<template>
    <VField
        v-slot="{ errorMessage }"
        v-model="model"
        :name="name"
        :rules="rules"
        type="radio">
        <div v-bind="$attrs">
            <label
                :class="labelClasses">
                {{ label }} {{ required ? '*' : '' }}
                <slot />
            </label>
            <fieldset :class="optionContainerClasses">
                <CoreRadio
                    v-for="option in options"
                    :key="option.value"
                    v-model="model"
                    :disabled="disabled || (option?.disabled ?? false)"
                    :label="option.label"
                    :value="option.value"
                    :input-classes="optionClasses"
                    :label-classes="optionLabelClasses"
                    :option-classes="optionWrapperClasses"
                    :name="name" />
            </fieldset>

            <div :class="errorClasses">
                {{ errorMessage }}
            </div>
        </div>
    </VField>
</template>

<script setup>
import CoreRadio from './CoreRadio.vue'

const props = defineProps({
    modelValue: {
        type: [String, Number],
        default: ''
    },
    name: {
        type: String,
        default: '',
        required: true
    },
    label: {
        type: String,
        default: ''
    },
    labelClasses: {
        type: String,
        default: 'form-label'
    },
    options: {
        type: Array,
        default: () => [],
        description: 'Array of objects with value, label and optional disabled property'
    },
    optionContainerClasses: {
        type: String,
        default: ''
    },
    optionWrapperClasses: {
        type: String,
        default: ''
    },
    optionClasses: {
        type: String,
        default: ''
    },
    optionLabelClasses: {
        type: String,
        default: ''
    },
    inputInvalidClasses: {
        type: String,
        default: 'is-invalid'
    },
    errorClasses: {
        type: String,
        default: 'invalid-feedback'
    },
    rules: {
        type: [String, Array, Object],
        description: 'Vee validate validation rules',
        default: ''
    },
    required: {
        type: Boolean,
        default: false
    },
    disabled: {
        type: Boolean,
        default: false
    }
})

const emits = defineEmits(['update:modelValue'])

const model = computed({
    get: () => props.modelValue,
    set: (newValue) => {
        emits('update:modelValue', newValue)
    }
});
</script>
